@import '../../../bpp-variables';

.cad-container {
  .cad-assigned-to {
    display: inline-block !important;
    width: 100%;
    text-align: center;

    .assign-to-dropdown {
      width: 250px !important;
      border-radius: 20px;
      display: inline-block;

      &__margin-right {
        margin-right: 8px;
      }
    }

    .cad-label {
      text-align: right;
      padding: 0px 10px;
      display: inline-block;
      width: 245px;
    }

    &__label {
      padding-right: 5px;
    }

    // &__margin-left {
    //   margin-left: 83px;
    // }
  }
  .process_tracker {
    ul.workflow {
      padding-top: 40px;

      li p {
        top: -38px;
      }
    }
  }

  .modal-error,
  .modal-error p {
    color: $bpp-subtle-warning;
    text-align: center;
  }

  .process_message {
    font-size: $bpp-font-size-reg;
    width: 90%;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
  }

  .business-dark-theme {
    .process_tracker ul.workflow li p {
      left: -65px;
      width: 145px;
    }

    .process_tracker ul.workflow li:last-child p {
      margin-top: -17px;
    }
  }

  .cad-title {
    font-weight: bold;
    margin-top: 10px;
  }

  .initiate-review {
    margin-top: -25px;
    margin-bottom: 40px;

    span {
      float: right;
    }
  }

  .btn-retry-ifu {
    padding: 3px 10px;
    border-radius: 7px;
  }

  .upload-ifu-icon {
    display: inline-block;
    margin-top: 8px;
    background-color: rgb(79, 80, 82);
    width: 22px;
    height: 22px;
    border-radius: 4px;

    i {
      margin-left: 4.9px;
      margin-top: 4px;
      font-size: 14px;
    }
  }
}

.underline-text.viewable-text.view-upload {
  color: #303235;
}
.file-display {
  a {
    color: #303235;
  }
}

.adjusted-image-excel {
  .img-excel {
    margin-right: auto;
    margin-left: auto;
    height: 145px;
    padding: 0px;

    .div-padding-b2 {
      width: 125px;
      margin-right: 0px;
    }

    svg {
      margin-top: 22px;
    }
  }
}
