@import "../../bpp-variables";

$padding-left: 10px;

.btn-action {
  font-size: 20px;
  display: inline-block;
  margin: 0px 10px;
}

.add-notes {
  margin-top: 2px;
  margin-right: 5px;
}

.business-header.row,
.business-sub-header.row,
.case-list.row {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 12px;
  padding-right: 12px;
}

.detail-view.business-sub-header {
  border-bottom: 1px solid #ccc;
  padding-top: 8px;
  padding-bottom: 8px;
}

.business-header {
  /* background-color: #32a1d9;
  color: #fff; */
  border-bottom: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.business-division {
  margin-top: 10px;
  border-top: 1px solid gray;
  padding-top: 10px;
}

.business-message-action {
  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.business-message {
  margin-bottom: 20px;
}

/* .image-fixed-size:hover, */
/* .img-preview:hover {
  -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
} */

.image-fixed-size-no-effects:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}

.detail-view.business-header {
  padding-bottom: 0px;
}

.detail-view.business-detail {
  padding-top: 0px;
}

.detail-container {
  min-height: 250px;
  margin-right: 8px;
  margin-left: -8px;
  border-color: #ccc;
  border-radius: 20px;
}

.detail-container.detail-container-large {
  min-height: 1060px;
  margin-left: 8px;
  border-color: #ccc;
}

.plan-window {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
}

.plan-height {
  min-height: 455px;
}

.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: -235%;
  left: 50%;
  margin-left: -80px;
  font-size: 14px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ccc transparent;
}

.link-popup {
  cursor: pointer;
  padding: 2px;
  color: #32a1d9;
  text-decoration: underline;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn-modal,
.btn-modal:focus,
.btn-link,
.btn-link:focus {
  cursor: pointer;
  color: #25292f;
}

.btn-modal:hover,
.btn-link:hover {
  color: #585d64;
}

.btn-modal:active,
.btn-link:active {
  color: #d1d2d3;
}

.btn-link-round {
  border-radius: 10px;
}

.case-precheck {
  background: rgba(0, 160, 223, 0.5);
  border-radius: 10px;
  color: black;
  display: inline-block;
  line-height: 18px;
  padding: 4px 12px 4px 12px;
  margin-left: 6px;
}

// Gen 2.0 Tag
.case-gen2 {
  border-radius: 5px;
  color: white;
  display: inline-block;
  line-height: 12px;
  padding: 4px 5px 4px 5px;
  margin-left: 5px;
  font-size: 12px;
  background: #2da4ad;
}

.case-tag {
  border-radius: 10px;
  color: black;
  display: inline-block;
  line-height: 18px;
  padding: 4px 12px 4px 12px;
  margin-left: 6px;
  &.false-de {
    background: rgba(225, 49, 70);
  }
  &.early-de {
    background: rgba(235, 180, 52);
  }
}

.false-de {
  border-radius: 10px;
  color: black;
  display: inline-block;
  line-height: 18px;
  padding: 4px 12px 4px 12px;
  margin-left: 6px;
}

// EPP Tag
.case-epp {
  background: #32a1d9;
  border-radius: 5px;
  color: white;
  display: inline-block;
  line-height: 12px;
  padding: 4px 5px 4px 5px;
  margin-left: 5px;
}

.col-lg-3,
.col-lg-2 {
  padding: 0px 5px 0px 15px;
}
.case-clinical-review {
  background: rgba(216, 250, 101, 1);
  border-radius: 10px;
  color: black;
  display: inline-block;
  line-height: 18px;
  padding: 4px 12px 4px 12px;
  margin-left: 6px;
}

.case-expedite-check {
  background: rgba(255, 107, 53, 0.5);
  border-radius: 10px;
  color: black;
  display: inline-block;
  line-height: 18px;
  padding: 4px 12px 4px 12px;
  margin-left: 6px;
}

.case-expedite-check.ir {
  font-size: 11px;
  padding: 3px 8px 3px 8px;
}

.comments-area {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-left: 5px;
  padding: 10px;
  min-height: 180px;
}

.disabled-text {
  color: #81848c;
  pointer-events: none;
}

.business-heading {
  background-color: #018abe;
  border-radius: 20px;
  padding: 4px;
  color: #ffffff;
  margin-bottom: 10px;
}

.right-bottom-img {
  position: relative;
  bottom: 0px;
  font-size: 14px;
  z-index: 1;
}

.relative {
  position: relative;
}

.right-bottom-img {
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.right-bottom-img-2 {
  position: absolute;
  bottom: 6px;
  right: 24px;
  cursor: pointer;
}

.fa.fa-trash-o.right-bottom-img {
  cursor: pointer;
}

.trash-color {
  color: #ff6666;

  &:hover {
    cursor: pointer;
  }
}

.corner {
  position: relative;
}

.mount-corner {
  position: absolute;
  top: 11px;
  right: 50px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.mount-corner-2 {
  position: absolute;
  top: 11px;
  right: 25px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.mount-corner:hover,
.mount-corner-2:hover {
  font-size: 20px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.mount-corner:active,
.mount-corner-2:active {
  color: #d1d2d3;
}

.pdf {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 24px;
  height: 127px;
  width: 100%;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.pdf-icon {
  width: 32px;
  padding-top: 25%;
}

.pdf-text {
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
}

.tracking-box {
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
  width: 100%;
  padding: 10px 15px;
  height: 127px;
}

.p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

.table-note {
  table-layout: fixed;
}

.table-date,
.table-status-user {
  min-width: 140px;
  width: 140px;
  max-width: 140px;
}

.table-status {
  min-width: 240px;
}

.table-created-by {
  width: 140px;
  max-width: 140px;
}

.table-comments {
  width: 50%;
}

.dark-options-offset {
  padding-top: 25px;
}

.page-heading.page-return {
  float: right;
  font-size: 14px;
  cursor: pointer;
}

.page-info {
  margin-top: 20px;
  padding: 10px;
  background-color: rgba(17, 17, 17, 0.06);
  border-radius: 10px;
}

.page-tab {
  background-color: #9ea3a8;
  display: inline-block;
  border-radius: 10px 10px 0px 0px;
  min-width: 175px;
  text-align: center;
  margin-top: 8px;
  margin-right: 8px;
  padding-top: 5px;
  padding-bottom: 2px;
  cursor: pointer;

  &-container {
    &--spaced {
      margin-top: 20px;

      .page-tab-content {
        border-radius: 10px;
      }
    }
  }
}

.page-tab.page-active {
  background-color: rgba(17, 17, 17, 0.06);
  font-weight: bold;
  cursor: default;
}

.page-tab-content {
  background-color: rgba(17, 17, 17, 0.06);
  border-radius: 0px 10px 10px 10px;
  padding: 15px 8px 5px 8px;
  line-height: 22px;
  vertical-align: bottom;
  min-height: 80vh;
}

.page-top-content-no-border {
  margin: 0px;
}

.page-top-content {
  border-bottom: 1px solid #adb0b4;
  margin: 0px;
}

.page-tab-top-section {
  margin-top: 20px;
}

.dropdown-menu-offset {
  left: -130px;
}

.business-dark-heading {
  background-color: #323539;
  border-radius: 10px 10px 0px 0px;
  color: #ffffff;
  padding: 3px 15px;
  margin-top: 8px;
}

.business-dark-theme {
  background-color: #c6c9cc;
  padding: 15px;
  border-radius: 0px 0px 10px 10px;
  min-height: 60px;
}

.button-tooltip {
  display: inline-block;
  margin-bottom: 10px;
}

.business-dark-theme-content {
  min-height: 70px;
}

.business-dark-theme-controls {
  padding-top: 10px;
  text-align: center;
}

.btn-light-link,
.btn-light-link:focus {
  color: #ffffff;
  cursor: pointer;
}

.btn-light-link:hover {
  font-size: 18px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.btn-light-link:active {
  color: #d1d2d3;
}

.btn-light-link:last-of-type {
  margin-right: 8px;
}

.btn-light-link:first-child {
  margin-right: 0px;
}

.btn-light-3 {
  padding: 5px 12px 6px 12px;
  margin: -1px 0px 1px 0px;
}

.btn-bpp-upload {
  background: transparent;
  color: black;
  border: solid 1px black;
  border-radius: 10px;
}

.btn-bpp-upload:hover {
  border: solid 1px #494d51;
}

.btn-margin-bottom {
  margin-bottom: 8px;
}

.btn-light.btn-light-wide {
  min-width: 120px;
}

.btn-light.btn-light-xwide {
  min-width: 150px;
}

.btn-room {
  margin-top: 10px;
}

.btn-inline {
  margin-top: 0px;
  margin-left: 15px;
  display: inline-block;
}

.btn-light,
.btn-light:focus,
.btn-light-2,
.btn-light-2:focus,
.btn-light-3,
.btn-light-3:focus {
  background-color: #3c3e42;
  color: #ffffff;
  border-radius: 10px;
}

.btn-light:hover,
.btn-light-2:hover,
.btn-light-3:hover {
  background-color: #494d51;
  color: #ffffff;
  border-radius: 10px;
}

.btn-light:active,
.btn-light-2:active,
.btn-light-3:active {
  background-color: #787e85;
  color: #ffffff;
  border-radius: 10px;
}

.btn-light:last-child {
  margin-left: 13px;
}

.btn-light:nth-child(2) {
  margin-left: 20px;
}

.btn-light:first-child {
  margin-left: 0px;
}

.btn-light-2 {
  margin-top: 10px;
}

.btn-light-disabled,
.btn-light-disabled:focus,
.btn-light-disabled:hover,
.btn-light-disabled:active {
  background-color: #787e85;
  color: #ffffff;
  border-radius: 10px;
  cursor: not-allowed;
}

.business-dark-setup {
  display: inline-block;
}

.img-preview {
  border-radius: 10px;
}

.business-dark-lg-6-r {
  padding-left: 5px;
}

.business-dark-lg-6-l {
  padding-right: 5px;
}

.business-dark-r-padding-top {
  padding-top: 13px;
}

.business-dark-theme-input {
  border-radius: 10px;
}

.business-dark-theme-col-8 {
  width: 59.99%;
}

.business-dark-theme-col-4 {
  width: 39.99%;
}

.business-dark-theme-input {
  width: 100%;
}

.feature-item.email + div {
  width: 100%;
}

.referral_email_input.referral-email-input-field {
  width: calc(100% - 10px);
}

.tracking-container {
  padding-left: 4px;
  width: 650px;
  display: inline-block;
  padding-top: 10px;
}

.ddm-container {
  padding-left: 4px;
  width: 825px;
  display: inline-block;
  padding-top: 5px;
}

.padding-left {
  padding-left: $padding-left;
}

.upload-container {
  padding-left: 4px;
  width: 650px;
  display: inline-block;
}

.shipping-container {
  width: auto;
  min-width: 650px;
  padding-left: 4px;
  display: inline-block;

  .inner.open[style] {
    overflow-x: hidden;
    max-height: 100% !important;
  }

  .bootstrap-select .dropdown-menu li {
    margin-bottom: 5px;
  }

  .select-address {
    margin-left: 0px;
    text-align: left;
  }
}

.select-address {
  width: 430px;
  border-radius: 10px;
  background-color: #fff;
}

.select-address[disabled] {
  background-color: rgb(198, 201, 204);
}

.btn-shipped,
.btn-shipped-2 {
  text-align: center;
  width: 180px;
  display: inline-block;
}

.btn-shipped-2:last-child {
  width: 200px;
  margin-left: -7px;
}

.btn-shipped:nth-child(2),
.btn-shipped:nth-child(3) {
  margin-top: 5px;
  // margin-left: 10px;
}

.viewable-text {
  padding-top: 5px;
  cursor: pointer;
  display: inline-block;
}

.button-centered {
  padding-top: 5px;
  width: 100%;
  text-align: center;
}

.btn-group-action {
  display: inline-block;
  height: 24px;
}

.btn-next {
  width: 40px;
  text-align: center;
  margin-left: 5px !important;
}

.btn-next:last-child {
  margin-left: 5px;
}

.padding-top-style-1 {
  padding-top: 5px;
}

.ship-title {
  // vertical-align: top;
  // padding-top: 5px;
  width: 145px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.shipped-title {
  vertical-align: top;
  // padding-top: 5px;
  width: 145px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.other-title {
  // vertical-align: top;
  width: 145px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.status__message--dark {
  position: fixed;
  background-color: #111;
  width: auto;
  color: #ccc;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 8px 5px;
  z-index: 1;
}
/* div#btncenter {
  margin: 0 auto;
  width: 50%;
} */

div#profilebtnsubmit {
  width: 200px;
  display: inline-block;
  padding-left: 15px;
}

.address-radio {
  padding-left: 20px;
  padding-bottom: 10px;
}

.detail-view {
  padding: 20px;
}

.detail-info {
  width: 100px;
  display: inline-block;
}

.detail-container {
  border-radius: 20px;
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 10px 10px;
}

.btn-default.btn-disabled {
  cursor: not-allowed;
}

.btn.btn-disabled {
  cursor: not-allowed;
}

.btn.btn-light.btn-disabled {
  border-radius: 10px;
}

.footer-top-warning {
  margin-top: 15px;
}

.min-date-width {
  display: inline-block;
  padding-right: 5px;
}

.setup-log-indent {
  padding-top: 10px;
}
.item-table {
  width: 100%;
}
.item-requestid {
  display: inline-block;
  // width: 25%;
  width: 20%;
}
.item-submissiondate {
  display: inline-block;
  // width: 25%;
  width: 18%;
}
.progress-submissiondate {
  display: inline-block;
  width: 25%;
}
.progress-status {
  display: inline-block;
  width: 35%;
}
.progress-requestnumber {
  display: inline-block;
  width: 10%;
}
.progress-type {
  display: inline-block;
  width: 30%;
}
.item-status {
  display: inline-block;
  // width: 43%;
  width: 55%;
}
.item-requestnumber {
  display: inline-block;
  width: 7%;
}
.selected-item-row > .item-requestnumber {
  padding-left: 5px;
}
.item-row > .item-requestnumber {
  padding-left: 5px;
}
.selected-item-row > .item-requestid {
  padding-left: 4px;
}
.item-row > .item-requestid {
  padding-left: 4px;
}
.selected-item-row > .item-status {
  padding-left: 2.5px;
}
.item-row > .item-status {
  padding-left: 2.5px;
}
.selected-item-row > .item-submissiondate {
  margin-left: -5px;
}
.item-row > .item-submissiondate {
  margin-left: -5px;
}

.item-heading > .progress-submissiondate {
  padding-left: 5px;
}

.selected-item-row > .progress-submissiondate {
  padding-left: 0px;
}
.item-row > .progress-submissiondate {
  padding-left: 0px;
}
.selected-item-row > .progress-requestnumber {
  padding-left: 4px;
}
.item-row > .progress-requestnumber {
  padding-left: 4px;
}
.selected-item-row > .progress-type {
  padding-left: 4px;
}
.item-row > .progress-type {
  padding-left: 4px;
}
.item-heading {
  padding-top: 5px;
}
.selected-item-row {
  border-radius: 20px;
  background-color: rgba(17, 17, 17, 0.06);
  padding-bottom: 5px;
  padding-left: 10px;
  padding-top: 5px;
  margin-bottom: 5px;
}
.item-row {
  padding-bottom: 5px;
  padding-left: 10px;
  padding-top: 5px;
  margin-bottom: 5px;
}

.item-row:hover {
  background-color: rgba(17, 17, 17, 0.06);
  border-radius: 20px;
}

.item-details-part-id {
  width: 12%;
  display: inline-block;
}
.item-details-description {
  width: 30%;
  display: inline-block;
}

.item-request {
  .item-details-description {
    & + .item-details-quantity-alt {
      padding-left: 25%;
    }
  }
}

.item-details-quantity {
  width: 6%;
  display: inline-block;
}

.item-details-template {
  width: 132px;
  display: inline-block;
}

.item-details-woref {
  width: 170px;
  display: inline-block;
}

.bpps__line-divison {
  border-bottom: 1px solid #adb0b4;
  margin-bottom: 5px;
  width: 98%;
}

.bpps__line-divison-cancel {
  border-bottom: 1px solid #9ea3a8;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.item-table {
  padding-top: 10px;
  cursor: pointer;
}

.progress-table {
  padding-top: 10px;
  cursor: pointer;
}

.right-arrow {
  float: right;
  padding-right: 10px;
}

.bpp-cozy-text-headlines {
  padding-bottom: 10px;
  margin: 0;
}

.selected-item-status {
  padding-left: 150px;
}

.modal-textarea-spacing {
  margin-top: 15px;
}

.modal-textarea {
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 10px 20px;
}

.modal-textarea-error,
.modal-textarea-error:focus {
  border-radius: 10px;
  border: 1px solid $bpp-subtle-warning;
  padding: 10px 20px;
}

.modal-textarea-error-hold-cancel {
  border: 1px solid $bpp-subtle-warning !important;
}

.modal-textarea-hold-cancel {
  width: 65%;
  float: right;
  margin-right: 25px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px 12px;
  margin-top: 0px;
}

textarea.modal-textarea-hold-cancel::placeholder {
  color: #303235;
}

.error-notes {
  border: 1px solid $bpp-subtle-warning;
}

.text-error {
  color: $bpp-subtle-warning;
}

.modal-error,
.modal-error p {
  color: $bpp-subtle-warning;
  text-align: center;
}

.bpp-10-padding {
  padding-bottom: 10px;
}

.bpp-10-top-padding {
  padding-top: 10px;
}

.special-5-padding {
  padding-bottom: 5px;
}

.left-align-radio {
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
}

input[type="radio"].address-format-radio {
  vertical-align: top;
  display: inline-table;
}

.remove-bottom-margin {
  display: inline-block;
  margin-bottom: 0px;
}

.ir-link {
  color: #c9e2fa;
  cursor: pointer;
}

.indicator-dot {
  background-color: #fc4c01;
  height: 8px;
  width: 8px;
  display: block;
  position: absolute;
  right: -4px;
  top: -4px;
  z-index: 2;
  border-radius: 50%;
}

.btn-action.btn-modal {
  position: relative;
}

.all-top {
  vertical-align: top;
}

.bpp-padding-left-btn {
  right: 50%;
  padding-left: 250px;
  padding-top: 15px;
}

.ship-address-label {
  display: inline-block;
}

.tracking-input {
  width: 393px;
  display: inline-block;
}

.date-input {
  width: 181px;
  display: inline-block;
}

.date-input::placeholder {
  font-size: 14px;
  padding-left: 1px;
  word-spacing: -2.8px;
  color: #555;
}

.ship-btn-container {
  text-align: center;
  width: 100%;
  display: inline-block;
  padding-top: 10px;
}

.vbpc-section {
  padding-left: 13px;
  margin-top: 5px;
  display: inline-block;
}

.btn-upload {
  vertical-align: top;
}

.view-upload {
  width: 100%;
  max-width: 200px;
  display: inline-block;
}

.case-mark-ship-btn {
  padding-top: 10px;
}

.case-mark-ship-section {
  padding-left: 12px;
}

.empty-mark-ship-section {
  padding-right: 0px;
}

.btn-mark-shipped {
  padding-left: 0px;
  margin-left: 0px;
}

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 10px !important;
}

.plan-window#tx_plan_window {
  height: 1130px;
  border: none;
}

.plan-window#production_tx_guide_window {
  height: 1130px;
  border: none;
}

.btn-light-link.progress-zip-download {
  margin-right: 0px;
}

div.csq-pdf > div:first-child {
  height: 1150px;
  border: none;
  padding-bottom: 10px;
}
.csq-pdf.pdfobject-container#csq_window {
  height: 1150px;
  border: none;
  padding-bottom: 10px;
}

.top-seperator {
  border-top: 0.7px solid rgb(158, 163, 168);
  padding-top: 15px;
  margin-top: 10px;
}

div.pr-dropdown {
  padding-top: 10px;
  display: inline-block;

  .css-2b097c-container,
  .css-14jk2my-container {
    width: 240px;
    display: inline-block;
    margin-left: 15px;
  }

  .css-f0arjc-control {
    border-radius: 10px;
  }

  .pr-list-read {
    margin-left: 10px;
    display: inline-block;
  }
}

.csr-box {
  margin-bottom: 7px;
}

.notes-right {
  float: right;
}

.bottom-text {
  margin-top: 5px;
  float: right;
}

.notes-header {
  margin-top: 5px;
  margin-bottom: 10px;
}

.notes-label {
  margin-bottom: 10px;
}

.dropdown-menu > li.disabled-action > a {
  color: #ccc;
}

.dropdown-menu > li.disabled-action {
  pointer-events: none;
}

.btn-confirm-disabled {
  cursor: not-allowed;
}

.expedite-shipping-label {
  margin-left: 5px;
}

.case-target-ship-date {
  margin-left: 100px;
}

.treatment-tab {
  text-align: center;
  width: 18%;
  cursor: pointer;
  margin-right: 8px;
  padding: 5px 15px;
  background-color: #9ea3a8;
  padding-bottom: 4px;
  white-space: nowrap;

  &__active {
    background-color: #323539;
  }
}

.treatment-guide {
  margin-bottom: 500px;

  .btn-cancel {
    background-color: transparent;
    color: #303235;
    border: 1px solid #303235;
  }
}

.incomplete-error {
  color: $bpp-subtle-warning-new;
  text-align: center;
}

.target-date {
  &.pull-right {
    padding-right: 8px;
  }

  & > :last-child {
    font-weight: normal;
  }
}

.target-date-flag {
  color: $bpp-subtle-warning-new;
  margin-left: 5px;
}

.ws-redo-flag {
  color: #0e63d5;
  margin-left: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.segmentation-target-date {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
}

.linked_id_div {
  margin-left: 20px;
  background-color: #adacbc;
  border-radius: 8px;
  padding: 4px 10px;
  width: fit-content;
}

/* External Target Override Modal */
.bpp-dark .external-target-override-modal-body {
  .form-group {
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
    label,
    input {
      flex: 0 1 50%;
    }
    label {
      text-align: right;
    }
    input {
      justify-self: left;
      padding: 6px 12px;
    }

    .inline-input {
      margin-left: 10px;

      &:not(textarea) {
        padding-left: 0;
      }
    }

    textarea {
      padding: 6px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 45%;
      font-size: 14px;
      color: #555;

      &.modal-textarea-error {
        border: 1px solid $bpp-subtle-warning;
      }
    }
  }
  .inline-label {
    width: auto;
  }

  .input-container {
    margin-left: 10px;
    display: inline-block;
    width: auto;
  }
}

.ship-date-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  color: #555;
}

.override-error-message {
  color: #d43f3a;
  opacity: 1;
}

.restrict-text {
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.restrict-text-single-row {
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.copy-text {
  display: inline;
}

.shrink--icon {
  font-size: 13px;
  margin-right: 3px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .copy-text {
    display: block;
  }
}
