.ireqid-width {
  width: 18%;
}

//Gen 2.0 Tag
.item-gen2 {
  background: #2da4ad;
  border-radius: 5px;
  color: white;
  line-height: 12px;
  padding: 4px 5px;
  margin-left: 5px;
  font-size: 14px;
  float: right;
}

.ir-row {
  position: relative;

  .expedited-icon {
    position: absolute;
    margin: auto;
    right: 20px;
    top: 5px;
  }

  .caseid-width {
    width: 20%;
  }
}

.ir-header {
  .name-width {
    width: auto;
    min-width: 17%;
  }
}
